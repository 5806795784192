<template>
  <main>
    <div class="carousel">
      <div class="desc active">
        <span>Úprava a barvení obočí a řas</span>
      </div>
      <div class="desc">
        <span>Klasická zdravotní masáž - Lymfodrenáž ruční</span>
      </div>
      <div class="desc">
        <span>Kosmetika NATINUEL - léčebná a estetická péče o pleť</span>
      </div>
    </div>
    <section class="info">
      <div class="container">
        <!-- <div class="row">
          <div class="col-lg-3 col-md-12">
            <span class="temporary-title">Bezpečná krása</span>
          </div>
          <div class="col-lg-9 col-md-12">
            <h2>Vyžaduje Vaši pozornost</h2>
            <p id="slogan">
              Máme zodpovědnost za zdraví společně, jde o zdraví nás všech i
              našich rodin.
            </p>
            <p id="requried">
              Aby jste si užili relaxační procedury, služby našeho zařízení a
              kosmetikování bez roušky, prosím dodružjte:
            </p>
            <p id="todo-1"><u>Nevstupujte bez roušky.</u></p>
            <p id="todo-2">
              <u>Než o váš začneme pečovat, udržujte odstup 2m od ostatních</u>
            </p>
            <p id="todo-3"><u>Nevstupujte s teplotou vyšší než 37 stupnů</u></p>
            <p id="todo-4">
              <u>Nevstupujte, pokud cítíte příznaky onemocnění</u>
            </p>
            <p id="todo-5">
              <u>V prostoru čekárny se smí nacházet pouze 1 klient</u>
            </p>
            <p id="end">
              Dodržujte prosím časový harmonogram, nechoďte ani dříve, ani
              později. <b>Děkujeme</b>.
            </p>
          </div>
        </div> -->
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <h1>Vítejte ve Finestudiu</h1>
          </div>
          <div class="col-lg-9 col-md-12">
            <!-- <h2>Dovolená</h2>
            <p>3.7.-9.7.2023<br />30.7.-6.8.2023</p> -->
            <h2>O nás</h2>
            <p>
              Fine studio je tady pro Vás již od roku 2007. Poskytujeme ucelené
              kosmetické a masérské zkrášlovací a regenerační služby.
            </p>
            <p>
              V naší nabídce najdete širokou škálu <strong>masáží</strong>. Od
              klasické <strong>zdravotní masáže zad</strong> přes
              <strong>lymfodrenáž</strong> až po velice oblíbenou masáž
              <strong>lávovými kameny</strong>.
            </p>
            <p>
              Skvělé nadčasové <strong>kosmetické ošetření</strong> Vaši pleť
              rozzáří, uzdraví, omladí a dodá potřebnou výživu, hydrataci a
              regeneraci. Používáme špičkovou italskou salónní kosmetiku
              <strong>NATINUEL</strong> - NOVÁ DIMENZE SALÓNNÍ PÉČE.
            </p>
          </div>
        </div>
      </div>
    </section>
    <div class="banner">
      <div class="desc active">
        <span>Jsme připraveni Vám pomoci<br />Budete v rukách odborníků</span>
      </div>
    </div>
  </main>
</template>

<script>
import $ from "jquery";

export default {
  name: "Homepage",
  data() {
    return {
      activeSlide: 0,
    };
  },
  methods: {
    carousel() {
      this.carouselInterval = setInterval(this.slide, 5000);
    },
    slide() {
      const elList = $(".carousel .desc");
      $(".carousel .active").removeClass("active");
      elList.eq(this.activeSlide).addClass("active");
      if (this.activeSlide === elList.length - 1) {
        this.activeSlide = 0;
      } else {
        this.activeSlide = this.activeSlide + 1;
      }
    },
  },
  mounted() {
    this.carousel();
  },
  beforeUnmount() {
    clearInterval(this.carouselInterval);
  },
};
</script>
